import React, { useEffect, useState } from 'react'
import { ProgressType } from '@/types/types'
import Lottie from 'lottie-react'
import tick from '@/assets/images/tick.svg'

interface ProgressProps {
  content: ProgressType
  active: boolean
  nextStep: () => void
}

export default function ProgressLottie(props: ProgressProps) {
  const [animationData, setAnimationData] = useState<any>(undefined)
  useEffect(() => {
    if (props.active) {
      if (props?.content?.src) {
        fetch(props.content.src)
          .then((response) => response.json())
          .then((data) => setAnimationData(data))
          .catch((error) => console.error('Error fetching animation data:', error))
      }
      document.querySelectorAll('.step-list').forEach((item, index) => {
        setTimeout(
          () => {
            item.classList.add('played')
          },
          1000 * (index + 1),
        )
      })
      setTimeout(() => {
        props.nextStep()
      }, 6000)
    }
  }, [props.active])

  return (
    <>
      {props?.content?.src && (
        <div className="mt-4">
          <Lottie className="max-w-60 mx-auto w-full" animationData={animationData} loop={false} />
        </div>
      )}
      {props?.content?.steps && (
        <ul className="mt-10">
          {props?.content?.steps.map((item, index) => {
            return (
              <li
                className="text-base text-black font-semibold mt-2 step-list flex item-center"
                key={index}
              >
                <img
                  className="absolute top-1/2 left-0 w-6 -translate-y-1/2 transition duration-500 opacity-0"
                  src={tick}
                  alt="tick"
                />
                {item}
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}
