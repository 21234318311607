import ReactPixel from 'react-facebook-pixel'
import * as amplitude from '@amplitude/analytics-browser'

export const trackPageView = () => {
  ReactPixel.pageView()
}
export const trackLead = () => {
  ReactPixel.track('Lead')
}
export const trackInitCheckout = (plan: string) => {
  ReactPixel.track('InitiateCheckout')
  amplitude.track('InitiateCheckout', { plan })
}
export const trackPurchase = () => {
  ReactPixel.track('Purchase', { value: 100.0, currency: 'USD' })
}
export const selectPaymentMethod = (method: string) => {
  amplitude.track('select_payment_method', { method })
}
export const clickPurchaseButton = (method: string) => {
  amplitude.track('click_purchase_button', { method })
}
export const backCheckout = () => {
  amplitude.track('back_checkout')
}
export const trackPaymentErrors = (e: any) => {
  amplitude.track('payment_error', { error: e })
}
