import React from 'react'
import { Button } from '@/components/Button/Button'
import thankYouDog from '@/assets/images/tankyou-dog.png'
import { useTranslation } from 'react-i18next'

interface TimerOverlay {
  onCloseOverlay: () => void
  isShow: boolean
}

export default function TimerOverlay(props: TimerOverlay) {
  const { t } = useTranslation()
  const handleClick = () => {
    localStorage.setItem('shouldShowOverlayExpired', 'false')
    props.onCloseOverlay()
  }
  return (
    <div className="fixed top-0 left-1/2 -translate-x-1/2 h-full  max-w-content w-full flex items-end">
      <div
        className={`${props.isShow ? 'opacity-50' : 'opacity-0'} fader absolute top-0 left-0 w-full h-full bg-black transition-all duration-500`}
        onClick={handleClick}
      ></div>
      <div
        className={`${props.isShow ? 'translate-y-0' : 'translate-y-full'} bg-background z-10 relative p-4 pb-6 rounded-t-3xl transition-all duration-500 w-full`}
      >
        <h2 className="mb-4 pt-0 text-xl3">
          {t('timerOverlayTitle1')} <br /> {t('timerOverlayTitle2')}
        </h2>
        <p className="italic font-medium">
          {t('timerOverlayDescription1')} <br /> {t('timerOverlayDescription2')}
        </p>
        <img className="max-w-60 mx-auto w-full mt-8" src={thankYouDog} alt="Thank you image" />
        <Button className="mt-8 btn-large" onClick={handleClick}>
          {t('wow, thank you')}
        </Button>
      </div>
    </div>
  )
}
