import React, { useEffect, useState } from 'react'
import appStore from '@/assets/images/app-store.png'
import enjoyPlan from '@/assets/images/enjoy_your_dog_plan.png'
import step2 from '@/assets/images/thankyou_step_2.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function ThankYou() {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const token = location.state?.token
  const customer = location.state?.customer
  const [appUrl, setAppUrl] = useState('')
  const [showPass, setShowPass] = useState(false)

  useEffect(() => {
    if (token === undefined) {
      navigate('/')
    } else {
      setAppUrl(`https://pawer.onelink.me/wq1F/a69noezj?access_token=${token}`)
    }
  }, [token])

  return (
    <div className="w-full pt-6 px-4 flex flex-col">
      <h2 className="text-xl3 text-center uppercase text-border2 font-bold mb-0 pt-0 ">
        {t('Thank you for purchase')}
      </h2>
      <p className="mb-8 text-black">{t('thankYouDescription')}</p>

      <p className="flex items-center font-semibold text-black">
        1. {t('Download the app from App Store')}
      </p>
      <div className="px-2">
        <a
          href={appUrl}
          className="btn mt-4 block w-full flex items-center justify-center p-2 rounded-lg bg-white border border-black"
          download
          target="_blank"
          rel="noreferrer"
        >
          <img src={appStore} className="max-w-36 w-full" alt="Download applicaton" />
        </a>
      </div>

      <p className="flex text-left items-center font-semibold text-black mt-6">
        2. {t('instructionThankYou2')}
      </p>
      <div className="grow shrink basis-auto flex items-end">
        <img src={step2} alt="Pawer image" className="mt-4 w-full px-7 " />
      </div>

      <p className="flex text-left items-center font-semibold text-black mt-6">
        3. {t('instructionThankYou3')}
      </p>
      <div className="border border-black rounded-xl bg-white mt-4">
        <div className="p-4 py-2 border-b border-black">
          <span className="block text-xs">{t('Email')}</span>
          <span className="block text-black">{customer.email}</span>
        </div>
        <div className="p-4 py-2 relative">
          <span className="block text-xs">{t('Password')}</span>
          <span className="block text-black">
            {!showPass ? <>••••••••</> : <>{customer.password}</>}
          </span>
          <button
            onClick={() => setShowPass((prevState) => !prevState)}
            className="absolute right-4 top-[16px]"
          >
            {!showPass ? (
              <svg
                width="32"
                height="26"
                viewBox="0 0 32 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.0403 13.0001C21.0403 15.7843 18.7824 18.0407 15.9982 18.0407C13.2141 18.0407 10.9577 15.7843 10.9577 13.0001C10.9577 10.2144 13.2141 7.95804 15.9982 7.95804C18.7824 7.95804 21.0403 10.2144 21.0403 13.0001Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9968 24.6437C22.069 24.6437 27.623 20.2777 30.75 12.9999C27.623 5.7222 22.069 1.3562 15.9968 1.3562H16.0032C9.93097 1.3562 4.377 5.7222 1.25 12.9999C4.377 20.2777 9.93097 24.6437 16.0032 24.6437H15.9968Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="32"
                height="26"
                viewBox="0 0 32 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4301 16.2928C11.5132 15.4453 10.9551 14.2936 10.9551 13.0017C10.9551 10.4134 13.2066 8.32717 16.0004 8.32717C17.3829 8.32717 18.6554 8.84541 19.5563 9.69291M20.9517 13.8301C20.5818 15.7347 18.9617 17.2378 16.9062 17.5833M7.47689 20.8781C4.94627 19.0384 2.80314 16.3512 1.25 13.001C2.81908 9.63614 4.97657 6.93418 7.52313 5.07972C10.0538 3.22526 12.9735 2.2183 16 2.2183C19.0441 2.2183 21.9622 3.24003 24.5088 5.10778M27.8769 8.35529C28.974 9.70479 29.9388 11.2625 30.7504 13.0003C27.6138 19.7286 22.071 23.7816 16.0004 23.7816C14.6243 23.7816 13.2673 23.5748 11.9629 23.1718M28.577 1.35498L3.42388 24.645"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      <p className="flex items-center font-semibold text-black mt-6">
        4. {t('Enjoy your personal dog plan')}
      </p>
      <div className="grow shrink basis-auto flex items-end">
        <img src={enjoyPlan} alt="Pawer image" className="mt-4 w-full px-7 " />
      </div>
    </div>
  )
}
