import React, { useEffect, useState } from 'react'
import { Answer, InputType } from '@/types/types'
import { useTranslation } from 'react-i18next'

interface InputProps {
  attributes: InputType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  error: string
  step: number
}

export default function Input(props: InputProps) {
  const { t } = useTranslation()
  const [showError, setShowError] = useState(props.error)
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let isValid = e.target.checkValidity()
    if (props.attributes.type === 'email') {
      isValid = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(e.target.value)
    }
    if (isValid) {
      props.onAnswer({ [props.name]: [e.target.value] })
      setShowError('')
    } else {
      props.handleRemoveAnswer({ [props.name]: [] })
      if (props.attributes.errorValidation) {
        setShowError(props.attributes.errorValidation)
      }
      if (props.attributes.type === 'email') {
        setShowError(t('invalidEmail'))
      }
    }
  }

  useEffect(() => {
    if (props.error) {
      setShowError(props.error)
    }
  }, [props.error])

  useEffect(() => {
    props.onAnswer({ [props.name]: [] }, props.step)
  }, [])

  return (
    <div>
      {props.attributes.label && <label className="!font-semibold">{props.attributes.label}</label>}
      <input
        type={props.attributes.type}
        onChange={(e) => handleChangeInput(e)}
        placeholder={props.attributes.placeholder}
        minLength={props.attributes.minLength}
        required={props.attributes.required}
        className={`${showError ? 'border-mark' : ''}  text-base rounded-lg px-6 py-4 font-semibold border border-border2 bg-transparent w-full placeholder:text-gray focus-visible:outline-none `}
      />
      {showError && <span className="text-mark block mt-1 text-xs font-normal">{showError}</span>}
    </div>
  )
}
