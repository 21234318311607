import { Customer, PaymentPlan } from '@/types/types'

const headers = {
  'Content-Type': 'application/json',
}

export const createTransaction = async (customer: Customer, plan: PaymentPlan) => {
  if (customer && plan) {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/create-transaction`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ customer, plan }),
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}
