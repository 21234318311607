import React, { useEffect } from 'react'
import { Answer, Answers, Question } from '@/types/types'
import Content from '@/components/QuestionsUI/Content'
import RadioButton from '@/components/QuestionsUI/RadioButton'
import Input from '@/components/QuestionsUI/Input'
import { Button } from '@/components/Button/Button'
import CheckboxButton from '@/components/QuestionsUI/CheckboxButton'
import { useTranslation } from 'react-i18next'
import ProgressLottie from '../QuestionsUI/ProgressLottie'

interface QuestionStepProps {
  question: Question
  error: string
  onAnswer: (answer: Answer, step?: number) => void
  onSubmit: () => void
  onShowNextButton: () => boolean
  onNextClick: () => void
  questionsLength: number
  handleRemoveAnswer: (answer: Answer) => void
  step: number
  active: boolean
  answers: Answers
}

export default function QuestionStep(props: QuestionStepProps) {
  const { t } = useTranslation()
  useEffect(() => {
    if (props.question.noRequiredFields) {
      props.onAnswer({ [props.question.name]: [''] }, props.step)
    }
  }, [props.question.noRequiredFields])

  return (
    <div
      className={
        (!props.active
          ? 'absolute top-0 opacity-0  pointer-events-none max-h-full overflow-hidden  '
          : 'opacity-100 top-0  overflow-hidden relative') +
        ' w-full transition duration-500 h-full container pb-28'
      }
    >
      {props.question?.paragraph && <p className="mt-4">{props.question.paragraph}</p>}
      <h2 className="col-span-2">
        {props.question.name}
        {props.question.nameMark && (
          <mark className="block bg-transparent text-mark">{props.question.nameMark}</mark>
        )}
      </h2>

      <div className={props.question?.grid ? ' columns-2 gap-x-2' : ''}>
        {props?.question?.components.map((el, index) => (
          <div key={index} className="step">
            {el?.content && <Content content={el.content} />}
            {el?.progress && (
              <ProgressLottie
                content={el.progress}
                active={props.active}
                nextStep={props.onNextClick}
              />
            )}
            {el?.radioButton && (
              <RadioButton
                onAnswer={props.onAnswer}
                name={`${props.question.fieldName ?? `radio-button-${props.step}`}`}
                attributes={el.radioButton}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                triggerNextStep={!props.question.nextButton ? props.onNextClick : () => {}}
              />
            )}
            {el?.checkboxButton && (
              <CheckboxButton
                step={props.step}
                onAnswer={props.onAnswer}
                handleRemoveAnswer={props.handleRemoveAnswer}
                name={`${props.question.fieldName ?? `checkbox-button-${props.step}`}`}
                attributes={el.checkboxButton}
              />
            )}
            {el?.input && (
              <Input
                name={el.input.name}
                handleRemoveAnswer={props.handleRemoveAnswer}
                onAnswer={props.onAnswer}
                attributes={el.input}
                error={props.error}
                step={props.step}
              />
            )}
          </div>
        ))}
      </div>

      <div className="button fixed bottom-10 max-w-content w-full container left-1/2 -translate-x-1/2 ">
        {props.step + 1 !== props.questionsLength && props.question.nextButton && (
          <Button
            onClick={props.onNextClick}
            disabled={props.onShowNextButton()}
            className="btn-large"
          >
            {t('Continue')}
          </Button>
        )}
        {props.step + 1 === props.questionsLength && (
          <Button
            disabled={props.onShowNextButton()}
            onClick={props.onSubmit}
            className="btn-large"
          >
            {t('Claim my plan')}
          </Button>
        )}
      </div>
    </div>
  )
}
