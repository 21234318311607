import React, { createContext, useState, useEffect, useContext } from 'react'
import { setSuccessPayment } from '@/helpers/setSuccessPayment'
import { useNavigate } from 'react-router-dom'

type PaddleContextType = any

export const PaddleContext = createContext<PaddleContextType>(null)

export const PaddleProvider = ({
  children,
  handleIsLoaded,
}: {
  children: React.ReactNode
  handleIsLoaded: () => void
}) => {
  const [paddle, setPaddle] = useState<any>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js'
    script.async = true

    script.onload = () => {
      const windowObj = window as any
      const Paddle = windowObj.Paddle
      Paddle.Environment.set('sandbox')
      Paddle.Setup({
        token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,
        eventCallback: (event: any) => {
          if (event?.name === 'checkout.completed') {
            setSuccessPayment(event?.data, navigate)
          }
          if (event?.name === 'checkout.loaded') {
            handleIsLoaded()
          }
        },
      })
      setPaddle(Paddle)
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return <PaddleContext.Provider value={paddle}>{children}</PaddleContext.Provider>
}

export const usePaddleContext = () => {
  const context = useContext(PaddleContext)
  if (context === undefined) {
    throw new Error('usePaddleContext was used outside of its Provider')
  }
  return context
}
