import React from 'react'
import dogImg from '@/assets/images/dog-img.png'
import { Answers, PaywallContent } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import { useTranslation } from 'react-i18next'

interface HeroProps {
  answers: Answers
  paywallContent: PaywallContent
  title?: string
  discount: boolean
}

export default function Hero(props: HeroProps) {
  const { t } = useTranslation()
  const currentLevel = getValue('array-current-level', props.answers)
  const breedString = getValue('dog-breed', props.answers) as string
  const breed = breedString.split('img:')[0]
  const breedImg = breedString.split('img:')[1]
  const primaryAim = getValue('array-primary-aim', props.answers)
  const trainingTime = getValue('training-time', props.answers)
  const primaryConcern = getValue('array-primary-concern', props.answers)

  return (
    <div className="">
      <h1
        className={`${props.discount ? 'bg-gradient-to-r from-lightBlue to-border bg-clip-text text-transparent' : 'mb-4'} text-center text-xl3 uppercase  px-4`}
      >
        {props.title}
      </h1>
      {props.discount && (
        <p className="leading-snug text-xl3 font-extrabold uppercase mb-2">
          🎁
          <span className="bg-gradient-to-r from-lightBlue to-border bg-clip-text text-transparent">
            {props.paywallContent.with45Discount}
          </span>
          🎁
        </p>
      )}
      <div className="grid grid-cols-2 gap-x-6 gap-y-2 px-4">
        <div className="row-span-3">
          <img className="w-full" src={breedImg ?? dogImg} alt="Dog image" />
        </div>
        {breed && (
          <div className="bg-white rounded-xl px-4 py-2 font-semibold text-black border-gradient">
            <h3 className="uppercase italic text-sm font-normal text-primary">
              {t('DOG’S BREED')}:
            </h3>
            {breed}
          </div>
        )}
        {Array.isArray(primaryAim) && (
          <div className="bg-white rounded-xl px-4 py-2 font-semibold text-black border-gradient">
            <h3 className="uppercase italic text-sm font-normal text-primary">{t('YOUR GOAL')}:</h3>
            {primaryAim[0]}
          </div>
        )}
        {trainingTime && (
          <div className="bg-white rounded-xl px-4 py-2 font-semibold text-black border-gradient">
            <h3 className="uppercase italic text-sm font-normal text-primary">
              {t('TIME FOR TRAINING')}:
            </h3>
            {trainingTime}
          </div>
        )}
        {Array.isArray(primaryConcern) && (
          <div className="bg-white rounded-xl px-4 py-2 font-semibold text-black border-gradient">
            <h3 className="uppercase italic text-sm font-normal text-primary">
              {t('Main concerns')}:
            </h3>
            {primaryConcern.slice(0, 4).map((el) => (
              <p className="text-left" key={el}>
                {el}
              </p>
            ))}
          </div>
        )}
        {Array.isArray(currentLevel) && (
          <div className="bg-white rounded-xl px-4 py-2 font-semibold text-black border-gradient">
            <h3 className="uppercase italic text-sm font-normal text-primary">
              {t('current level')}:
            </h3>
            <div className="grid grid-cols-2 gap-x-4 grid-cols-auto">
              {currentLevel.slice(0, 8).map((el) => (
                <p className=" text-left" key={el}>
                  {el}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
