import React, { useRef } from 'react'
import { Answer, RadioButtonType } from '@/types/types'

interface RadioButtonProps {
  attributes: RadioButtonType
  onAnswer: (answer: Answer) => void
  triggerNextStep: () => void
  name: string
}

export default function RadioButton(props: RadioButtonProps) {
  const checkbox = useRef<HTMLInputElement>(null)

  const handleChange = () => {
    props.onAnswer({
      [props.name]: [
        props.attributes.text + (props.attributes.img ? 'img:' + props.attributes.img : ''),
      ],
    })
    setTimeout(() => {
      props.triggerNextStep()
    }, 300)
  }

  return (
    <div>
      <label
        className={`${checkbox.current?.checked ? '!bg-checked border-borderChecked' : ''} font-inputWeight border transition duration-300 border-border  mb-2 mt-0  rounded-lg px-6 py-4 cursor-pointer flex items-center bg-input`}
      >
        {props.attributes?.textIcon && (
          <span className="block mr-4 text-xl2">{props.attributes?.textIcon}</span>
        )}
        {props.attributes.text}
        <input
          type="radio"
          onChange={handleChange}
          onClick={handleChange}
          name={props.name}
          value={props.attributes.text}
          className="hidden"
          ref={checkbox}
        />
      </label>
    </div>
  )
}
