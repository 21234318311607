import React, { useEffect, useState } from 'react'
import { Answers, PaymentPlan } from '@/types/types'
import { usePaddleContext } from '@/components/PaddleCheckout/PaddleProvider'
import { getValue } from '@/helpers/getObjectValue'
import { createTransaction } from '@/helpers/paddleHelpers'

interface CheckoutFormProps {
  plan: PaymentPlan
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const Paddle = usePaddleContext()
  const storedData = localStorage.getItem('quizResult')
  const locale = localStorage.getItem('i18nextLng')
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const userEmail = getValue('email', answers) as string
  const userName = (getValue('your-name', answers) as string) ?? ('Without name' as string)
  const customerPortalID = localStorage.getItem('customerPortalId') as string
  const customer = { name: userName, email: userEmail, customerPortalID }
  const [transactionID, setTransactionID] = useState('')
  const [customerID, setCustomerID] = useState('')

  useEffect(() => {
    createTransaction(customer, props.plan).then((data) => {
      setCustomerID(data.customer_id)
      setTransactionID(data.id)
    })
  }, [])

  useEffect(() => {
    if (Paddle && transactionID && customerID) {
      Paddle.Checkout.open({
        settings: {
          displayMode: 'inline',
          theme: 'light',
          locale: locale ?? 'en',
          allowedPaymentMethods: ['apple_pay', 'card', 'paypal'],
          frameTarget: 'payment-form',
          frameInitialHeight: 450,
        },
        transactionId: transactionID,
        customer: {
          id: customerID,
        },
      })
    }
  }, [Paddle, transactionID, customerID])

  return (
    <>
      <div className="payment-form relative h-[820px]"></div>
    </>
  )
}
