import React, { useEffect, useState } from 'react'
import Layout from '@/components/Layout'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '@/components/Button/Button'
import StripeCheckout from '@/components/StripeCheckout/Stripe'
import PaddleCheckout from '@/components/PaddleCheckout/Paddle'
import { useTranslation } from 'react-i18next'
import { backCheckout, trackInitCheckout } from '@/helpers/facebookPixelEvents'
import ErrorBoundary from '@/components/ErrorBoundary'

export default function Checkout() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const hasQuizResult = localStorage.getItem('quizResult') ?? false
  const plan = JSON.parse(localStorage.getItem('activePlan') ?? '')
  const paymentMethod = location?.state?.paymentMethod ?? 'stripe'
  const paywallContent = location?.state?.paywallContent ?? ''

  const handleReturnToPaywall = () => {
    navigate(-1)
  }

  const handleCloseCheckout = () => {
    backCheckout()
    localStorage.setItem('isDiscount45', 'true')
    handleReturnToPaywall()
  }

  useEffect(() => {
    if (!plan || !searchParams || !hasQuizResult) {
      handleReturnToPaywall()
    }
    trackInitCheckout(plan.name)
  }, [])

  return (
    <Layout>
      {hasQuizResult && (
        <div className="px-4 pt-1 flex flex-col h-full pb-6">
          {isLoading && <div className="spinner z-30 my-10 mx-auto"></div>}
          <Button onClick={handleCloseCheckout} className="btn-close"></Button>
          <h1 className="text-center text-xl3 text-black pb-7">{t('Select payment method')}</h1>
          {paymentMethod === 'stripe' && (
            <ErrorBoundary>
              <div className="flex w-full shrink grow basis-auto">
                <StripeCheckout
                  paywallContent={paywallContent}
                  handleIsLoading={(loading: boolean) => setIsLoading(loading)}
                  plan={plan}
                  searchParams={searchParams}
                />
              </div>
            </ErrorBoundary>
          )}
          {paymentMethod === 'paddle' && (
            <div className="">
              <PaddleCheckout
                paywallContent={paywallContent}
                handleIsLoaded={() => setIsLoading(false)}
                plan={plan}
                searchParams={searchParams}
              />
            </div>
          )}
        </div>
      )}
    </Layout>
  )
}
