import React from 'react'
import { ReviewType } from '@/types/types'

export default function Review(props: ReviewType) {
  return (
    <div className={'bg-white border border-grayBlue rounded-lg p-4' + ' ' + props.className}>
      {props.date ? (
        <>
          <div className="flex items-star justify-between mb-2">
            <div>
              <h3 className="text-xl font-bold text-black">{props.title}</h3>
              <ul className="flex items-center justify-start">
                {props.stars.map((stars) => {
                  return (
                    <li key={stars} className="mr-2">
                      <svg
                        className="w-3 h-auto"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.11555 1.67634C6.49076 0.965193 7.50924 0.965193 7.88445 1.67634L8.91009 3.62029C9.05478 3.89454 9.31851 4.08614 9.62404 4.13901L11.7898 4.51374C12.5821 4.65082 12.8968 5.61946 12.3364 6.19606L10.8045 7.77222C10.5884 7.99457 10.4877 8.3046 10.5318 8.61152L10.8447 10.7871C10.9592 11.5829 10.1352 12.1816 9.41364 11.8268L7.44125 10.857C7.16299 10.7201 6.83701 10.7201 6.55875 10.857L4.58636 11.8268C3.86481 12.1816 3.04084 11.5829 3.1553 10.7871L3.46816 8.61152C3.5123 8.3046 3.41157 7.99457 3.19546 7.77221L1.66359 6.19606C1.10319 5.61946 1.41792 4.65082 2.21021 4.51374L4.37596 4.13901C4.68149 4.08614 4.94522 3.89454 5.08991 3.62029L6.11555 1.67634Z"
                          fill="#FFC842"
                          stroke="#FFC842"
                        />
                      </svg>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className={props.class === 'reversed' ? 'flex flex-col-reverse' : ''}>
              <p className="text-base text-black text-end">
                <time>{props.date}</time>
              </p>
              <p className="text-base text-black  text-end">{props.name}</p>
            </div>
          </div>
          <blockquote className="text-primary font-medium italic text-md">{props.quote}</blockquote>
        </>
      ) : (
        <>
          <div className="flex items-star justify-between mb-2">
            <h3 className="text-base font-semibold text-black">{props.title}</h3>
            <ul className="flex items-center justify-end">
              {props.stars.map((stars) => {
                return (
                  <li key={stars} className="ml-2">
                    <svg
                      className="w-3 h-auto"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.11555 1.67634C6.49076 0.965193 7.50924 0.965193 7.88445 1.67634L8.91009 3.62029C9.05478 3.89454 9.31851 4.08614 9.62404 4.13901L11.7898 4.51374C12.5821 4.65082 12.8968 5.61946 12.3364 6.19606L10.8045 7.77222C10.5884 7.99457 10.4877 8.3046 10.5318 8.61152L10.8447 10.7871C10.9592 11.5829 10.1352 12.1816 9.41364 11.8268L7.44125 10.857C7.16299 10.7201 6.83701 10.7201 6.55875 10.857L4.58636 11.8268C3.86481 12.1816 3.04084 11.5829 3.1553 10.7871L3.46816 8.61152C3.5123 8.3046 3.41157 7.99457 3.19546 7.77221L1.66359 6.19606C1.10319 5.61946 1.41792 4.65082 2.21021 4.51374L4.37596 4.13901C4.68149 4.08614 4.94522 3.89454 5.08991 3.62029L6.11555 1.67634Z"
                        fill="#FFC842"
                        stroke="#FFC842"
                      />
                    </svg>
                  </li>
                )
              })}
            </ul>
          </div>
          <blockquote className="text-gray text-md">{props.quote}</blockquote>
        </>
      )}
    </div>
  )
}
