import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/assets/scss/style.scss'
import { BrowserRouter as Router, Routes, Navigate, Route } from 'react-router-dom'
import Quiz from '@/components/Quiz/Quiz'
import Paywall from '@/components/Paywall/Paywall'
import i18n from './i18n'
import Checkout from '@/components/Checkout/Checkout'
import CreateAccount from '@/components/CreateAccount/CreateAccount'
import ThankYou from '@/components/ThankYou/ThankYou'
import ReactPixel from 'react-facebook-pixel'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

ReactPixel.init('729340255895218')
amplitude.init('a7df591945fbb3b24873396c5ddc3b84', { defaultTracking: true })
const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 })
amplitude.add(sessionReplayTracking)

document.documentElement.lang = i18n.language
const queryParams = new URLSearchParams(window.location.search)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Router>
    <Routes>
      <Route
        path="/:currentQuestionString"
        Component={(props) => <Quiz {...props} lang={i18n.language} quizFile="quiz.json" />}
      />
      <Route
        path="/"
        element={<Navigate to={`/0/${queryParams ? `?${queryParams.toString()}` : ''}`} />}
      />
      <Route
        path="/quiz-2/:currentQuestionString"
        Component={(props) => <Quiz {...props} lang={i18n.language} quizFile="quiz2.json" />}
      />
      <Route
        path="/quiz-2/"
        element={<Navigate to={`/quiz-2/0/${queryParams ? `?${queryParams.toString()}` : ''}`} />}
      />
      <Route
        path="/paywall/"
        Component={(props) => (
          <Paywall
            paymentMethod={'stripe'}
            paywallFile="paywall.json"
            {...props}
            lang={i18n.language}
          />
        )}
      />
      <Route
        path="/paywall-2/"
        Component={(props) => (
          <Paywall
            paymentMethod={'paddle'}
            paywallFile="paywall.json"
            {...props}
            lang={i18n.language}
          />
        )}
      />
      <Route
        path="/paywall-3/"
        Component={(props) => (
          <Paywall
            paymentMethod={'stripe'}
            paywallFile="paywall.json"
            {...props}
            lang={i18n.language}
          />
        )}
      />
      <Route
        path="/paywall-4/"
        Component={(props) => (
          <Paywall
            paymentMethod={'stripe'}
            paywallFile="paywall-stripe-50.json"
            {...props}
            lang={i18n.language}
          />
        )}
      />
      <Route path="/checkout/" Component={(props) => <Checkout {...props} />} />
      <Route path="/register/" Component={(props) => <CreateAccount {...props} />} />
      <Route path="/thank-you/" Component={(props) => <ThankYou {...props} />} />
      <Route
        path="/test-register/"
        Component={(props) => <CreateAccount testMode={true} {...props} />}
      />
    </Routes>
  </Router>,
)
