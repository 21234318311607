export const formattedPrice = (price: number) => {
  return price
    .toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace('.', ',')
}
export const formatDollarsAndCents = (number: number) => {
  const dollars = Math.floor(number)
  const cents = Math.round((number - dollars) * 100)
  return [dollars, cents]
}
