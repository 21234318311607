import React, { useEffect, useState } from 'react'
import { Button } from '@/components/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useValidationMessages from '@/customHooks/useValidationMessages'
import { Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import { useTranslation } from 'react-i18next'
import { trackPurchase } from '@/helpers/facebookPixelEvents'

interface IFormInput {
  name: string
  email: string
  password: string
}
interface User {
  email: string
  password: string
}

export default function CreateAccount(props: { testMode?: boolean }) {
  const { t } = useTranslation()
  const validationErrors = useValidationMessages()
  const storedData = localStorage.getItem('quizResult')
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const customerPortalID = localStorage.getItem('customerPortalId') as string
  const userEmail = getValue('email', answers) as string
  const initialName = (getValue('your-name', answers) as string) ?? ('' as string)
  const schema = yup.object().shape({
    name: yup.string().required(validationErrors.requiredName),
    email: yup
      .string()
      .required(validationErrors.requiredEmail)
      .matches(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/, validationErrors.invalidEmail),
    password: yup
      .string()
      .required(validationErrors.requiredPassword)
      .min(8, validationErrors.minLengthPassword),
  })
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema), mode: 'onChange' })

  const [name, setName] = useState(initialName)
  const [registerErrors, setRegisterErrors] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const result = location.state?.res
  const inputClasses =
    'text-base rounded-lg px-6 py-4 font-semibold border border-grayBlue bg-input w-full placeholder:text-gray focus-visible:outline-none'
  const createAccount = async (customer: User): Promise<{ status: number; body: any }> => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CREATE_ACCOUNT_ENDPOINT}/api/v1/auth/sign-up?_id=${customerPortalID}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(customer),
        },
      )
      const data = response.json()
      return { status: response.status, body: data }
    } catch (e) {
      return { status: 412, body: { error: e } }
    }
  }

  useEffect(() => {
    if (!props.testMode && result === undefined) {
      navigate('/')
    }
    trackPurchase()
  }, [result])

  const onSubmit = (data: IFormInput) => {
    const customer = { email: data.email, password: data.password }
    createAccount(customer).then((res) => {
      if (res.status !== 412) {
        res.body.then((body: any) => {
          if (res.status === 200) {
            const token = body.refresh
            navigate('/thank-you', { state: { token, customer } })
          } else if (res.status === 409) {
            setRegisterErrors(body.error)
          } else {
            setRegisterErrors(body.error)
          }
        })
      }
    })
  }

  const handleInputChange = (fieldName: any, value: string) => {
    setValue(fieldName, value, { shouldValidate: true })
    if (fieldName === 'name') {
      setName(value)
    }
  }

  return (
    <div className="w-full py-6 px-4 flex flex-col">
      <h2 className="text-xl3 text-center text-black font-bold pt-0">{t('Create account')}</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col shrink grow basis-auto"
      >
        <label htmlFor="your-name" className="mt-2">
          {t('Name')}
        </label>
        <input
          className={`${errors.name ? 'border-mark' : ''} ${inputClasses}`}
          {...register('name')}
          id="your-name"
          onChange={(e) => {
            handleInputChange('name', e.target.value)
          }}
          value={name}
        />
        {errors.name && (
          <p className="text-mark block mt-1 text-xs font-normal text-left">
            {errors.name.message}
          </p>
        )}

        <label htmlFor="your-email" className="mt-2">
          {t('Email')}
        </label>
        <input
          className={`${errors.email ? 'border-mark' : ''} ${inputClasses}`}
          {...register('email')}
          id="your-email"
          value={userEmail}
          contentEditable={false}
        />
        {errors.email && (
          <p className="text-mark block mt-1 text-xs font-normal text-left">
            {errors.email.message}
          </p>
        )}

        <label htmlFor="your-password" className="mt-2">
          {t('Password')}
        </label>
        <input
          className={`${errors.password ? 'border-mark' : ''} ${inputClasses}`}
          {...register('password')}
          id="your-password"
        />
        {errors.password && (
          <p className="text-mark block mt-1 text-xs font-normal text-left">
            {errors.password.message}
          </p>
        )}
        <div className={'text-mark block mt-1 text-xs font-normal text-left'}>{registerErrors}</div>
        <div className="shrink grow basis-auto mt-4 flex items-end">
          <Button type="submit" className="btn-large">
            {t('continue with email')}
          </Button>
        </div>
      </form>
    </div>
  )
}
