import React, { ChangeEvent, useRef } from 'react'
import { Answer, CheckboxButtonType } from '@/types/types'

interface CheckboxButtonProps {
  attributes: CheckboxButtonType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  step: number
}

export default function CheckboxButton(props: CheckboxButtonProps) {
  const checkbox = useRef<HTMLInputElement>(null)
  const reset: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[data-reset]')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && !e.target.dataset.reset) {
      props.onAnswer({ [props.name]: [props.attributes.text] })
      reset.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
    } else if (!e.target.checked && !e.target.dataset.reset) {
      props.onAnswer({ [props.name]: ['remove-' + props.attributes.text] })
    }
    if (e.target.dataset.reset) {
      const targetName = e.target.getAttribute('name')
      const inputs: NodeListOf<HTMLInputElement> =
        document.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element) {
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
        element.checked = element.getAttribute('name') === targetName && e.target === element
      })
      props.onAnswer({ [props.name]: [props.attributes.text] })
    }
  }

  return (
    <div>
      <label
        className={
          "flex bg-input items-center checkbox font-inputWeight border relative border-border mb-2 mt-0 rounded-lg px-6 py-4 pr-14 cursor-pointer  transition duration-300  after:absolute after:content=[']' after:top-1/2 after:w-5 after:h-5  after:border after:border-borderCheckbox  after:right-6 after:rounded-xs after:-translate-y-1/2 " +
          (checkbox.current?.checked
            ? '!bg-checked after:bg-white border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
            : '')
        }
      >
        {props.attributes?.textIcon && (
          <span className="block mr-4 text-xl2">{props.attributes?.textIcon}</span>
        )}
        {props.attributes.text}
        <input
          type="checkbox"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          name={props.name}
          value={props.attributes.text}
          className="hidden"
          ref={checkbox}
          data-reset={props.attributes.resetCheckbox}
        />
      </label>
    </div>
  )
}
