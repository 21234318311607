import { Customer, PaymentPlan, Subscription } from '@/types/types'

const stripeSecretKey =
  process.env.REACT_APP_STRIPE_MODE === 'live'
    ? process.env.REACT_APP_STRIPE_SECRET_KEY ?? ''
    : process.env.REACT_APP_STRIPE_SECRET_KEY_DEV ?? ''

const RequestHeader = {
  Authorization: `Bearer ${stripeSecretKey}`,
  'Content-Type': 'application/x-www-form-urlencoded',
}
const endpoint = 'https://api.stripe.com/v1'

export const createStripeCustomer = async (
  customer: Customer,
  customerPortalID: string,
  email: string,
) => {
  if (customer) {
    try {
      const bodyParams = new URLSearchParams()
      bodyParams.append('name', customer.name)
      bodyParams.append('email', customer.email)

      if (customerPortalID) {
        bodyParams.append('metadata[customer_portal_id]', customerPortalID)
      }
      if (email) {
        bodyParams.append('metadata[email]', email)
      }

      const response = await fetch(`${endpoint}/customers`, {
        method: 'POST',
        headers: RequestHeader,
        body: bodyParams,
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}

export const checkIfCustomerExist = async (customer: Customer) => {
  if (customer) {
    try {
      const response = await fetch(`${endpoint}/customers?email=${customer.email}`, {
        method: 'GET',
        headers: RequestHeader,
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}

export const checkExistSubscription = async (id: string) => {
  if (id) {
    try {
      const response = await fetch(`${endpoint}/subscriptions?customer=${id}`, {
        method: 'GET',
        headers: RequestHeader,
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}

export const deleteSubscription = async (id: string) => {
  if (id) {
    try {
      const response = await fetch(`${endpoint}/subscriptions/${id}`, {
        method: 'DELETE',
        headers: RequestHeader,
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}

export const createScheduleSubscriptionForCustomer = async (
  subscription: Subscription,
  plan: PaymentPlan,
) => {
  if (subscription.id && plan.nextProductStripeID) {
    try {
      const bodyParams = new URLSearchParams()
      bodyParams.append('from_subscription', subscription.id)
      const response = await fetch(`${endpoint}/subscription_schedules`, {
        method: 'POST',
        headers: RequestHeader,
        body: bodyParams,
      })
      const res = await response.json()
      return await UpdateScheduleSubscriptionForCustomer(subscription, plan, res)
    } catch (e) {
      return e
    }
  }
}

export const UpdateScheduleSubscriptionForCustomer = async (
  subscription: Subscription,
  plan: PaymentPlan,
  res: any,
) => {
  if (subscription.id && plan.nextProductStripeID) {
    try {
      const now = Math.floor(Date.now() / 1000)
      const endDate = now + 31 * 25 * 60 * 60
      const bodyParams = new URLSearchParams()
      bodyParams.append('phases[0][items][0][price]', subscription.plan)
      bodyParams.append('phases[0][items][0][quantity]', '1')
      bodyParams.append('phases[0][start_date]', subscription.current_period_start)
      bodyParams.append('phases[0][end_date]', subscription.current_period_end)
      bodyParams.append('phases[1][items][0][price]', plan.nextProductStripeID)
      bodyParams.append('phases[1][items][0][quantity]', '1')
      bodyParams.append('phases[1][start_date]', subscription.current_period_end)
      bodyParams.append('phases[1][end_date]', endDate.toString())
      const response = await fetch(`${endpoint}/subscription_schedules/${res.id}`, {
        method: 'POST',
        headers: RequestHeader,
        body: bodyParams,
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}

export const createSubscriptionForCustomer = async (id: string, plan: PaymentPlan) => {
  if (id && plan.stripeProductID) {
    try {
      const bodyParams = new URLSearchParams()
      bodyParams.append('customer', id)
      bodyParams.append('payment_behavior', 'default_incomplete')
      bodyParams.append('items[0][price]', plan.stripeProductID)
      bodyParams.append('payment_settings[save_default_payment_method]', 'on_subscription')
      if (plan.stripeCouponID) {
        bodyParams.append('coupon', plan.stripeCouponID)
      }
      bodyParams.append('expand[]', 'latest_invoice.payment_intent')

      const response = await fetch(`${endpoint}/subscriptions`, {
        method: 'POST',
        headers: RequestHeader,
        body: bodyParams,
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}

export const checkPaymentIntent = async (paymentIntent: string) => {
  if (paymentIntent) {
    try {
      const response = await fetch(`${endpoint}/payment_intents/${paymentIntent}`, {
        method: 'GET',
        headers: RequestHeader,
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}
