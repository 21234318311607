import React, { useState } from 'react'
import { PaymentPlan, PaywallContent, Plan } from '@/types/types'
import { PaddleProvider } from '@/components/PaddleCheckout/PaddleProvider'
import CheckoutForm from '@/components/PaddleCheckout/CheckoutForm'
import { formatPlan } from '@/helpers/formatPlan'
import Subtotal from '@/components/CheckoutUI/Subtotal'

interface PaddleCheckoutProps {
  plan: Plan
  paywallContent: PaywallContent
  searchParams?: URLSearchParams
  handleIsLoaded: () => void
}

export default function PaddleCheckout(props: PaddleCheckoutProps) {
  const [isDiscount45] = useState(localStorage.getItem('isDiscount45') === 'true')
  const [plan] = useState<PaymentPlan | undefined>(
    props.plan ? formatPlan(props.plan, isDiscount45) : undefined,
  )

  return (
    <>
      {plan && (
        <PaddleProvider handleIsLoaded={props.handleIsLoaded}>
          <Subtotal paywallContent={props.paywallContent} plan={plan} />
          <CheckoutForm plan={plan} />
        </PaddleProvider>
      )}
    </>
  )
}
