import React from 'react'
import { PaywallContent, Plan } from '@/types/types'
import { formatDollarsAndCents, formattedPrice } from '@/helpers/formatPrice'
import { useNavigate } from 'react-router-dom'
import Lottie from 'lottie-react'
import animationData from '../../assets/images/button_get_my_plan.json'
import { useTranslation } from 'react-i18next'

interface PlansProps {
  privacyText: string
  paywallContent: PaywallContent
  paymentMethod: string
  isDiscount45: boolean
  activePlan: number
  handlePlan: (index: number) => void
  plans: Plan[]
}

export function Plans(props: PlansProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const percentsText = props.isDiscount45
    ? props.paywallContent.percentText45
    : props.paywallContent.percentText30

  const handleCheckout = () => {
    localStorage.setItem('activePlan', JSON.stringify(props.plans[props.activePlan]))
    navigate('/checkout', {
      state: { paymentMethod: props.paymentMethod, paywallContent: props.paywallContent },
    })
  }

  return (
    <div className="plans px-4">
      {props.plans.map((el, index) => {
        const perDayPrice = props.isDiscount45
          ? formatDollarsAndCents(el.pricePerDay45)
          : formatDollarsAndCents(el.pricePerDay30)
        const price = props.isDiscount45 ? el.discountedPrice45 : el.discountedPrice30
        return (
          <div
            key={el.name}
            onClick={() => {
              props.handlePlan(index)
            }}
            className={`${!el.mostPopular && index === props.activePlan ? 'bg-white' : ''} ${index === props.activePlan ? 'active bg-btn border-black text-black' : 'text-gray border-grayBlue'} p-2 plan-item  border flex mt-2 items-center justify-between rounded-xl  px-6 py-4 relative cursor-pointer transition-all`}
          >
            {el.mostPopular && (
              <div className="bg-greenGradient absolute px-4 py-1 rounded-2xl text-white font-semibold text-md left-6 top-0 -translate-y-1/2">
                {t('MOST POPULAR')}
              </div>
            )}
            <div>
              <span className="block font-bold text-xl uppercase">
                {el.name} {t('Plan')}
              </span>
              <span className="block italic text-md">
                ${formattedPrice(el.originalPrice)}-{percentsText}=
                <span className="font-semibold">${formattedPrice(price)}</span>
              </span>
            </div>
            <div className="text-price font-bold flex items-center">
              <span>
                <span className="text-xl2 align-super">$</span>
                {perDayPrice[0]}
              </span>
              <span>
                <span className="text-xl3 block leading-6">{perDayPrice[1]}</span>
                <span className="text-md block font-medium">{t('per day')}</span>
              </span>
            </div>
          </div>
        )
      })}
      <p
        className="mt-2 text-left text-xs px-4 font-light text-black link-decoration"
        dangerouslySetInnerHTML={{ __html: props.privacyText }}
      />
      <div onClick={handleCheckout} className="mt-2 bg-transparent p-0 cursor-pointer">
        <Lottie className="w-full" animationData={animationData} loop={true} autoplay={true} />
      </div>
    </div>
  )
}
