import { formattedPrice } from '@/helpers/formatPrice'
import React, { useState } from 'react'
import { PaymentPlan, PaywallContent } from '@/types/types'
import { useTranslation } from 'react-i18next'

export default function Subtotal(props: { plan: PaymentPlan; paywallContent: PaywallContent }) {
  const { t } = useTranslation()
  const [isDiscount45] = useState(localStorage.getItem('isDiscount45') === 'true')
  const savedMoney = props.plan.originalPrice - props.plan.discountedPrice
  const discountPercent = isDiscount45
    ? props.paywallContent.percentText45
    : props.paywallContent.percentText30
  return (
    <>
      <div className="flex justify-between">
        <span>
          {props.plan.name} {t('Personal Training Plan')}
        </span>
        <span>${formattedPrice(props.plan.originalPrice)}</span>
      </div>
      <div className="flex justify-between mt-1">
        <span>
          {discountPercent} {t('introductory offer discount')}
        </span>
        <span className="text-mark font-semibold">-${formattedPrice(savedMoney)}</span>
      </div>
      <div className="flex justify-between border-t border-primary mt-2 pt-2 mb-6">
        <span className="font-bold text-black text-xl">{t('Total')}</span>
        <span className="text-right">
          <span className="block font-bold text-black text-xl">
            ${formattedPrice(props.plan.discountedPrice)}
          </span>
          <span className="block mt-2 text-md text-mark font-semibold">
            {t('You just saved')} ${formattedPrice(savedMoney)} (-{discountPercent} {t('off')}){' '}
          </span>
        </span>
      </div>
    </>
  )
}
