import React, { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentPlan, PaywallContent, Subscription } from '@/types/types'
import Subtotal from '@/components/CheckoutUI/Subtotal'
import { Button } from '@/components/Button/Button'
import { setSuccessPayment } from '@/helpers/setSuccessPayment'
import { NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createScheduleSubscriptionForCustomer } from '@/helpers/stripeHelpers'
import PayPallIcon from '@/assets/images/PayPal.svg'
import ApplePay from '@/assets/images/Apple_Pay_logo white.svg'
import {
  clickPurchaseButton,
  selectPaymentMethod,
  trackPaymentErrors,
} from '@/helpers/facebookPixelEvents'

interface CheckoutFormProps {
  plan: PaymentPlan
  paywallContent: PaywallContent
  navigate: NavigateFunction
  subscription: Subscription
  handleError: (error: string) => void
  handleIsLoading: (loading: boolean) => void
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const defaultButton = { title: t('Submit'), class: 'btn' }
  const [counter, setCounter] = useState(0)
  const [submitButton, setSubmitButton] = useState(defaultButton)
  const [isActiveSubmitButton, setIsActiveSubmitButton] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleClickPurchaseButton(submitButton.class === 'submit-dft' ? 'card' : submitButton.class)

    if (!stripe || !elements) {
      return
    }

    props.handleIsLoading(true)

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_PAYMENT_RETURN_URL ?? '',
      },
      redirect: 'if_required',
    })

    if (result.error) {
      if (result.error.message) {
        props.handleError(result.error.message)
      }
    } else {
      if (props.plan.nextProductStripeID) {
        createScheduleSubscriptionForCustomer(props.subscription, props.plan).then(() => {
          setSuccessPayment(result, props.navigate)
        })
      } else {
        setSuccessPayment(result, props.navigate)
      }
    }

    props.handleIsLoading(false)
  }

  const handleChangePaymentMethod = (e: { value: { type: string }; complete: boolean }) => {
    setIsActiveSubmitButton(e.complete)
    setCounter((prev) => ++prev)
    switch (e?.value?.type) {
      case 'paypal':
        setSubmitButton({ title: '', class: 'paypall' })
        break
      case 'apple_pay':
        setSubmitButton({ title: '', class: 'apple-pay' })
        break
      default:
        setSubmitButton(defaultButton)
    }
    if (counter > 1) {
      selectPaymentMethod(e.value.type)
    }
    props.handleIsLoading(false)
  }

  const handleClickPurchaseButton = (method: string) => {
    clickPurchaseButton(method)
  }

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)} className="w-full flex flex-col">
        <Subtotal paywallContent={props.paywallContent} plan={props.plan} />
        <PaymentElement
          onChange={(e) => {
            handleChangePaymentMethod(e)
          }}
          onLoadError={(e) => {
            trackPaymentErrors(e)
          }}
          options={{ paymentMethodOrder: ['paypal', 'card', 'apple_pay'] }}
          onReady={() => props.handleIsLoading(false)}
        />
        <div className="mt-4">
          <Button disabled={!isActiveSubmitButton} className={`submit ${submitButton.class}`}>
            {submitButton.class === 'paypall' && (
              <img src={PayPallIcon} alt="PaypallIcon" width={90} height={24} />
            )}
            {submitButton.class === 'apple-pay' && (
              <img src={ApplePay} alt="ApplePay" width={55} height={24} />
            )}
            {submitButton.title}
          </Button>
        </div>
      </form>
    </>
  )
}
