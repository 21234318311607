import { Answers } from '@/types/types'

export const getValue = (fieldName: string, object: Answers) => {
  for (const entry of Object.values(object)) {
    const value = entry[0][fieldName]
    if (value !== undefined) {
      const key = Object.keys(entry[0])[0]
      if (key.includes('array')) {
        return value
      }
      return value.length > 1 ? value : value[0]
    }
  }
}
