import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Layout from '@/components/Layout'
import QuestionStep from '@/components/QuestionStep/QuestionStep'
import { Answer, Answers, Question } from '@/types/types'
import { Progress } from '@/components/Progress/Progress'
import pet from '@/assets/images/pet.svg'
import { useTranslation } from 'react-i18next'
import { preCreateAccount } from '@/helpers/preCreateAccount'
import { getValue } from '@/helpers/getObjectValue'
import { trackPageView } from '@/helpers/facebookPixelEvents'

interface QuizProps {
  quizFile: string
  lang: string
}

export default function Quiz(props: QuizProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [questions, setQuestions] = useState<Question[]>([])
  const [mode, setMode] = useState<string>('')
  const [questionsLength, setQuestionsLength] = useState(0)
  const [answers, setAnswers] = useState<Answers>({ 0: [] })
  const { currentQuestionString } = useParams()
  const currentQuestion = parseInt(currentQuestionString ?? '0')
  const [paywallUrl, setPaywallUrl] = useState('')
  const [error, setError] = useState('')
  const location = useLocation()

  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length <= 1 && obj.constructor === Object
  }

  useEffect(() => {
    trackPageView()
  }, [currentQuestion])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await import(`../../locales/${props.lang}/${props.quizFile}`)
        const emptyAnswers = Array.from({ length: response?.questions?.length }, (_, index) => [
          index,
          [],
        ])
        if (isEmptyObject(answers)) {
          setAnswers(Object.fromEntries(emptyAnswers))
        }
        setQuestions(response?.questions)
        setPaywallUrl(response?.paywall)
        setMode(response?.mode)
      } catch (error) {
        console.error('Error fetching quiz data:', error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setQuestionsLength(questions?.length)
  }, [questions])

  useEffect(() => {
    if (isEmptyObject(answers)) {
      const { pathname } = location
      if (!pathname.includes('/0/')) {
        const nextPathname = location.pathname.replace(`/${currentQuestion}/`, '/0/')
        window.location.href = nextPathname
      }
    }
  }, [answers])

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1
    const nextPathname = location.pathname.replace(`/${currentQuestion}/`, `/${nextQuestion}/`)
    navigate(nextPathname)
    localStorage.setItem('answers', JSON.stringify(answers))
  }

  const handleAnswer = (answer: Answer, step: number = currentQuestion) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers }
      const answerKey = Object.keys(answer)[0]
      if (newAnswers[step]) {
        const answerIndex = newAnswers[step].findIndex((item) => {
          return Object.keys(item)[0] === answerKey
        })
        if (answerKey.includes('array') && answerIndex > -1) {
          const currentValue = Object.values(answer)[0][0]
          if (currentValue && !currentValue.includes('remove-')) {
            newAnswers[step][answerIndex][answerKey] = [
              ...newAnswers[step][answerIndex][answerKey],
              ...Object.values(answer)[0],
            ]
          } else {
            newAnswers[step][answerIndex][answerKey].splice(
              newAnswers[step][answerIndex][answerKey].indexOf(currentValue.slice(7)),
              1,
            )
          }
        } else {
          if (answerIndex >= 0) {
            newAnswers[step][answerIndex][answerKey] = answer[answerKey]
          } else {
            newAnswers[step].push(answer)
          }
        }
      } else {
        newAnswers[step].push(answer)
      }
      return newAnswers
    })
  }

  const handleRemoveAnswer = (answer: Answer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers }
      const answerKey = Object.keys(answer)[0]
      const answerIndex = newAnswers[currentQuestion].findIndex((item) => {
        return Object.keys(item)[0] === answerKey
      })
      if (answerIndex >= 0) {
        newAnswers[currentQuestion][answerIndex][answerKey] = []
      }
      return newAnswers
    })
  }

  const handleShowNextButton = () => {
    const hasValues = answers[currentQuestion].length
    if (hasValues) {
      const index = answers[currentQuestion].findIndex((item) => {
        return Object.values(item)[0].length === 0
      })
      return index >= 0
    }
    return !hasValues
  }

  const handleQuizSubmit = () => {
    const userEmail = getValue('email', answers) as string
    preCreateAccount(userEmail).then((data) => {
      if (data.body.user?.id) {
        const endTime = new Date()
        endTime.setTime(endTime.getTime() + 5 * 60 * 1000)
        localStorage.setItem('quizResult', JSON.stringify(answers))
        localStorage.setItem('customerPortalId', data.body.user.id)
        localStorage.setItem('endTimer', endTime.toISOString())
        localStorage.setItem('shouldShowOverlayExpired', 'true')
        localStorage.setItem('shouldShowPercent45Overlay', 'true')
        localStorage.setItem('isDiscount45', 'false')
        navigate(paywallUrl)
      } else {
        setError(data.body.error)
      }
    })
  }

  return (
    <Layout className={mode}>
      <div className="flex items-center justify-center container">
        <img width="48" height="48" className="mr-4 w-12" src={pet} alt="pets" />
        <h1 className="bg-background text-lg text-center">{t('Pawer: Dog&puppy training')}</h1>
      </div>
      <div className="mt-4 text-center text-xs container font-normal">
        {t('Question')}: {currentQuestion + 1} {t('of')} {questionsLength}
      </div>
      <div className="container">
        <Progress
          value={questionsLength === 0 ? 0 : ((currentQuestion + 1) * 100) / questionsLength}
        />
      </div>
      <div className="relative grow shrink basis-auto flex flex-col">
        {questions?.map((el, index) => {
          return (
            <QuestionStep
              handleRemoveAnswer={handleRemoveAnswer}
              onAnswer={handleAnswer}
              onSubmit={handleQuizSubmit}
              onNextClick={handleNextQuestion}
              onShowNextButton={handleShowNextButton}
              key={index}
              error={error}
              active={currentQuestion === index}
              step={index}
              answers={answers}
              question={el}
              questionsLength={questionsLength}
            />
          )
        })}
      </div>
    </Layout>
  )
}
