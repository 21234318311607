import React, { useEffect, useState } from 'react'
import { ContentType } from '@/types/types'
import Review from './Review'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import Lottie from 'lottie-react'
import 'swiper/css'

interface ContentProps {
  content: ContentType
}

export default function Content(props: ContentProps) {
  const { t } = useTranslation()
  const [animationData, setAnimationData] = useState<any>(undefined)
  const [days, setDate] = useState(new Date())
  useEffect(() => {
    if (props?.content?.lottie?.src) {
      fetch(props.content.lottie.src)
        .then((response) => response.json())
        .then((data) => setAnimationData(data))
        .catch((error) => console.error('Error fetching animation data:', error))
    }
  }, [props.content.lottie?.src])

  useEffect(() => {
    const newDate = new Date()
    newDate.setDate(newDate.getDate() + 28)
    setDate(newDate)
  }, [])
  return (
    <>
      {props?.content?.date && (
        <div className="flex justify-center mb-4">
          <div className="text-white bg-gradient text-center text-lg font-bold py-2 px-6 rounded-lg">
            {props.content.date.text +
              days.toLocaleString('en-us', { month: 'short', day: 'numeric' })}
          </div>
        </div>
      )}
      {props?.content?.paragraph && (
        <p
          className={props?.content?.paragraph.bold && 'font-bold'}
          dangerouslySetInnerHTML={{ __html: props.content.paragraph.text }}
        ></p>
      )}
      {props?.content?.smallParagraph && (
        <p className="max-w-72 m-auto mt-2">
          <small className="text-md text-gray">{props.content.smallParagraph.text}</small>
        </p>
      )}
      {props?.content?.smallSpan && (
        <p className="text-start text-xs mt-2">
          <small className="text-xs font-normal">{props.content.smallSpan.text}</small>
        </p>
      )}
      {props?.content?.image && (
        <div className={props?.content?.image.className && 'min-w-image -left-image-gap relative'}>
          <img
            className={
              (props?.content?.image.className && 'w-full') +
              ' max-w-full min-w-60 mx-auto mt-6 w-auto'
            }
            width={20}
            height={20}
            alt="Some content"
            src={props?.content?.image.src}
          />
        </div>
      )}
      {props?.content?.lottie && (
        <div className={props?.content?.lottie.className && 'min-w-image -left-image-gap relative'}>
          <Lottie animationData={animationData} />
        </div>
      )}
      {props?.content?.imageAbsolute && (
        <div className="min-w-image -left-image-gap absolute top-1/4 -translate-y-1/3">
          <img
            className="max-w-full  mx-auto mt-6 w-full"
            width={20}
            height={20}
            alt="Some content"
            src={props?.content?.imageAbsolute.src}
          />
        </div>
      )}
      {props?.content?.reviews && (
        <>
          {props?.content?.slider ? (
            <Swiper
              modules={[Autoplay]}
              className="!overflow-visible mt-10"
              spaceBetween={8}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {props?.content?.reviews.map((review, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Review
                      className="mt-2"
                      title={review.title}
                      quote={review.quote}
                      stars={review.stars}
                      date={review?.date}
                      name={review?.name}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          ) : (
            <div>
              <h2 className="text-start mb-2 mt-6">{t('Recent reviews')}</h2>
              {props?.content?.reviews.map((review, index) => {
                return (
                  <Review
                    className="mt-2"
                    key={index}
                    title={review.title}
                    quote={review.quote}
                    stars={review.stars}
                    date={review?.date}
                    name={review?.name}
                  />
                )
              })}
            </div>
          )}
        </>
      )}
    </>
  )
}
