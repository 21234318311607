import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from '@/locales/en/translations.json'
import translationDE from '@/locales/de/translations.json'
import XHR from 'i18next-http-backend'

const options = {
  order: ['querystring', 'localStorage', 'sessionStorage', 'navigator'],
  lookupQuerystring: 'lang',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
}
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
}

i18n
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: options,
    resources,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en', 'de'],
    fallbackLng: 'en',
  })

export default i18n
