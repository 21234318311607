import React, { useEffect, useState } from 'react'
import Layout from '@/components/Layout'
import { useNavigate } from 'react-router-dom'
import { Answers, PaywallContent } from '@/types/types'
import Hero from '@/components/PaywallUI/Hero'
import Timer from '@/components/PaywallUI/Timer'
import TimerOverlay from '@/components/PaywallUI/TimerOverlay'
import { Plans } from '@/components/PaywallUI/Plans'
import Review from '@/components/QuestionsUI/Review'
import Percent45Overlay from '@/components/PaywallUI/Percent45Overlay'
import { trackLead } from '@/helpers/facebookPixelEvents'

interface PaywallProps {
  lang: string
  paywallFile: string
  paymentMethod: string
}

export default function Paywall(props: PaywallProps) {
  const navigate = useNavigate()
  const storedData = localStorage.getItem('quizResult') ?? false
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const [paywallContent, setPaywallContent] = useState<PaywallContent>()
  const [isDiscount45] = useState(localStorage.getItem('isDiscount45') === 'true')
  const [timerIsExpired, setTimerIsExpired] = useState(false)
  const [activePlan, setActivePlan] = useState(0)
  const [shouldShowOverlayExpired, setShouldShowOverlayExpired] = useState(
    localStorage.getItem('shouldShowOverlayExpired') !== 'false',
  )
  const [shouldShowPercent45Overlay, setShouldShowPercent45Overlay] = useState(
    localStorage.getItem('shouldShowPercent45Overlay') !== 'false',
  )

  const handlePlan = (index: number) => {
    setActivePlan(index)
  }

  const handleExpiredTimer = () => {
    setTimerIsExpired(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await import(`../../locales/${props.lang}/${props.paywallFile}`)
        setPaywallContent(response)
      } catch (error) {
        console.error('Error fetching paywall data:', error)
      }
    }
    fetchData()
    trackLead()
  }, [])

  useEffect(() => {
    if (timerIsExpired && shouldShowOverlayExpired) {
      setShouldShowOverlayExpired(true)
    }
  }, [timerIsExpired])

  useEffect(() => {
    if (!answers) {
      navigate('/')
    }
  }, [answers])

  return (
    answers && (
      <Layout>
        {paywallContent && (
          <>
            <Hero
              answers={answers}
              paywallContent={paywallContent}
              title={paywallContent?.title}
              discount={isDiscount45}
            />
            <Timer
              paywallContent={paywallContent}
              isDiscount45={isDiscount45}
              onTimerExpired={handleExpiredTimer}
            />
          </>
        )}

        {paywallContent?.choosePlanBlock && (
          <div className="bg-white text-center border-gradient mx-4 my-6 py-2">
            <h3 className="text-xl3 bg-gradient-to-r from-lightBlue to-border bg-clip-text text-transparent font-extrabold">
              {paywallContent?.choosePlanBlock.title}
            </h3>
            <p className="italic text-black font-medium px-10 mt-2">
              {paywallContent?.choosePlanBlock.description}
            </p>
          </div>
        )}

        {paywallContent?.plans && (
          <Plans
            activePlan={activePlan}
            paywallContent={paywallContent}
            handlePlan={handlePlan}
            paymentMethod={props.paymentMethod}
            privacyText={paywallContent.plans.privacyText}
            plans={paywallContent?.plans.items}
            isDiscount45={isDiscount45}
          />
        )}

        {paywallContent?.benefitsBlock && (
          <div className="mt-2">
            <h3 className="text-center text-white text-xl text-extrabold p-4 mt-6 bg-grayBlue">
              {paywallContent?.benefitsBlock.title}
            </h3>
            {paywallContent?.benefitsBlock?.items && (
              <ul className="list-disc list-outside px-4 pl-8 mt-4">
                {paywallContent?.benefitsBlock?.items?.map((el) => (
                  <li className="italic leading-snug font-medium text-black" key={el}>
                    {el}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {paywallContent?.featuredBlock && (
          <div className="mt-6 bg-white text-center p-2">
            <h3 className="text-xl text-black font-extrabold">
              {paywallContent?.featuredBlock.title}
            </h3>
            <div className="mt-1 flex items-center justify-center flex-wrap">
              {paywallContent?.featuredBlock.items?.map((el) => (
                <img className="h-7 w-auto my-1 mx-3" key={el} src={el} alt="Image" />
              ))}
            </div>
          </div>
        )}

        {paywallContent?.testimonialsBlock && (
          <div className="mt-6 px-4">
            <h3 className="px-12  min-[364px]:px-14 min-[394px]:px-16 min-[412px]:px-20 text-center text-xl font-extrabold bg-gradient-to-r from-lightBlue to-border bg-clip-text text-transparent mb-2">
              {paywallContent?.testimonialsBlock.title}
            </h3>
            {paywallContent?.testimonialsBlock?.reviews?.map((el) => (
              <Review
                className="mt-1"
                key={el.title + el.name}
                title={el.title}
                quote={el.quote}
                stars={el.stars}
                date={el.date}
                name={el.name}
                class={el.class}
              />
            ))}
          </div>
        )}

        {paywallContent?.moneyBackBlock && (
          <div className="mt-6 px-4">
            <h3 className="text-center text-xl font-extrabold text-black mb-2">
              {paywallContent?.moneyBackBlock.title}
            </h3>
            <div className="flex text-left gap-4 items-start">
              <p className="w-full text-left text-sm text-black">
                {paywallContent?.moneyBackBlock.content}
              </p>
              <img
                className="w-1/2"
                src={paywallContent?.moneyBackBlock.img}
                alt="100% money-back guarantee"
              />
            </div>
            <p
              className=" text-center link-decoration text-xs2 mt-2 text-black mb-4"
              dangerouslySetInnerHTML={{ __html: paywallContent?.moneyBackBlock.description ?? '' }}
            />
          </div>
        )}

        {paywallContent?.plans && (
          <Plans
            activePlan={activePlan}
            paywallContent={paywallContent}
            handlePlan={handlePlan}
            paymentMethod={props.paymentMethod}
            privacyText={paywallContent.plans.privacyText}
            plans={paywallContent?.plans.items}
            isDiscount45={isDiscount45}
          />
        )}

        {paywallContent?.noMoneyBlock && (
          <div className="bg-white text-center my-6 mx-4 border-gradient py-2 px-4">
            <h3 className="text-center leading-snug text-xl2 font-extrabold bg-gradient-to-r from-lightBlue to-border bg-clip-text text-transparent">
              {paywallContent?.noMoneyBlock.title}
            </h3>
            <p className="italic mt-2 text-black text-md2 font-medium px-4">
              {paywallContent?.noMoneyBlock.description}
            </p>
          </div>
        )}

        {paywallContent?.plans?.items[activePlan] && (
          <p className="px-4 text-xs pb-6">
            {paywallContent?.plans?.items[activePlan]?.information}
          </p>
        )}
        <div
          className={`${shouldShowOverlayExpired && timerIsExpired && !isDiscount45 ? 'opacity-100 visibility' : 'opacity-0 delay-500  invisible'} `}
        >
          <TimerOverlay
            isShow={shouldShowOverlayExpired && timerIsExpired && !isDiscount45}
            onCloseOverlay={() => {
              setShouldShowOverlayExpired(false)
            }}
          />
        </div>
        <div
          className={`${shouldShowPercent45Overlay && isDiscount45 ? 'opacity-100 visibility' : 'opacity-0 delay-500  invisible'} `}
        >
          <Percent45Overlay
            isShow={shouldShowPercent45Overlay && isDiscount45}
            onCloseOverlay={() => {
              setShouldShowPercent45Overlay(false)
            }}
          />
        </div>
      </Layout>
    )
  )
}
