export const preCreateAccount = async (email: string): Promise<{ status: number; body: any }> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CREATE_ACCOUNT_ENDPOINT}/api/v1/account/pre-create`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      },
    )
    const data = await response.json()
    return { status: response.status, body: data }
  } catch (e) {
    return { status: 412, body: { error: e } }
  }
}
