import React, { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import animationData from '../../assets/images/dogs_timer.json'
import { useTranslation } from 'react-i18next'
import { PaywallContent } from '@/types/types'

interface TimerProps {
  onTimerExpired: () => void
  isDiscount45: boolean
  paywallContent: PaywallContent
}

export default function Timer(props: TimerProps) {
  const { t } = useTranslation()
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [timeDifference, setTimeDifference] = useState<number | null>(null)

  const startTimer = () => {
    if (endTime) {
      const intervalId = setInterval(() => {
        const currentTime = new Date()
        const difference = endTime.getTime() - currentTime.getTime()
        if (difference <= 0) {
          clearInterval(intervalId)
          setTimeDifference(0)
        } else {
          setTimeDifference(difference)
        }
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }

  useEffect(() => {
    const storedEndTime = localStorage.getItem('endTimer')
    if (storedEndTime) {
      setEndTime(new Date(storedEndTime))
    }
  }, [])

  useEffect(() => {
    if (endTime) {
      const currentTime = new Date()
      const difference = endTime.getTime() - currentTime.getTime()
      if (difference > 0 && !props.isDiscount45) {
        startTimer()
      } else {
        setTimeDifference(0)
      }
    }
  }, [endTime])

  useEffect(() => {
    if (timeDifference === 0) {
      props.onTimerExpired()
    }
  }, [timeDifference])

  const minutes = Math.floor((timeDifference ?? 0) / (1000 * 60))
  const seconds = Math.floor(((timeDifference ?? 0) % (1000 * 60)) / 1000)

  return (
    <>
      {endTime !== null && (
        <div className="mt-6">
          <Lottie className="w-full" animationData={animationData} />
          <div className="bg-greenGradient w-full text-center p-4 text-white">
            <p className="uppercase font-medium">{props.paywallContent.yourOffer30ExpiresIn}</p>
            <p className="text-xl4 font-extrabold mt-2 uppercase">
              {minutes < 10 ? '0' : ''}
              {minutes}:{seconds < 10 ? '0' : ''}
              {seconds} {t('mins')}
            </p>
          </div>
        </div>
      )}
    </>
  )
}
