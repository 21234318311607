import React from 'react'

interface LayoutProps {
  children: React.ReactNode
  className?: string
}

const Layout = (props: LayoutProps) => {
  return <main className={props.className + ' pt-5 w-full flex flex-col'}>{props.children}</main>
}

export default Layout
